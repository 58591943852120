import { BiCodeAlt } from "react-icons/bi";
import { GiWindTurbine } from "react-icons/gi";
import { MdBuildCircle } from "react-icons/md";
import { SlArrowDown } from "react-icons/sl";
import { IoLayersOutline } from "react-icons/io5";
import React, { useEffect, useState } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Hero } from 'components/Hero';
import wordsPT from '../../i18n/pt/home.pt.json'
import wordsGB from '../../i18n/en/home.en.json'
import BasicBtn from "components/BasicBtn";
import CustomMetaTags from "components/CustomMetaTags";
import { World } from "components/ui/world";
import { GoogleGeminiEffect } from "components/ui/google-gemini-effect";
import { useScroll, useTransform } from "framer-motion";

export default function Home() {
	var offer: { title: string, slogan: string }[];
	const { t, i18n } = useTranslation();
	const [video, setVideo] = useState<boolean>(false)

	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, [])

	if (i18n.language === "en") {
		offer = wordsGB.Home.Section3
	} else {
		offer = wordsPT.Home.Section3
	}

	const ref = React.useRef(null);
	const { scrollYProgress } = useScroll({
		target: ref,
		offset: ["start start", "end start"],
	});

	const pathLengthFifth = useTransform(scrollYProgress, [0, 0.8], [0, 1.2]);
	const pathLengthFourth = useTransform(scrollYProgress, [0, 0.8], [0.015, 1.2]);
	const pathLengthThird = useTransform(scrollYProgress, [0, 0.8], [0.014, 1.2]);
	const pathLengthSecond = useTransform(scrollYProgress, [0, 0.8], [0.015, 1.2]);
	const pathLengthFirst = useTransform(scrollYProgress, [0, 0.8], [0.019, 1.2]);

	return (
		<>
			<CustomMetaTags
				title="ROOTKey - Securing Data Validity and Authenticity"
				description="Secure Your Data with ROOTKey: Cybersecurity and Decentralized Identity Management Solutions. Protecting Data Integrity and Privacy for a Safer Digital Future."
				keywords="ROOTKey, data security, data integrity, decentralized identity management, cybersecurity, web3, zkp"
				ogTitle="ROOTKey - Securing Data Validity and Authenticity"
				ogDescription="Secure Your Data with ROOTKey: Cybersecurity and Decentralized Identity Management Solutions. Protecting Data Integrity and Privacy for a Safer Digital Future."
				canonical="https://rootkey.ai/"
			/>

			<main id='Home'>
				<Hero.Root className="overflow-visible">
					<Hero.Title fade={'fade-right'} text='Home.Hero.title' />
					<Hero.Subtitle fade={'fade-right'} text='Home.Hero.platformHero' />
					<Hero.Button fade={null} href={"/platform"} arrow text='Home.Hero.btnHero' />
					{window.innerWidth > 1200 ? <World /> : <Hero.Img fade={null} imgUrl='https://media.notcybersec.com/website/Why/whyHero.png' />}
					{window.innerWidth > 1200 && <span className="playBtn" onClick={() => setVideo(true)}>
						<svg width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#fff" fill="none"><path d="M24.35,42.59V21.41A.49.49,0,0,1,25.1,21L41.77,31.59a.49.49,0,0,1,0,.82L25.1,43A.49.49,0,0,1,24.35,42.59Z" /><circle cx="32" cy="32" r="25.48" /></svg>
					</span>}
				</Hero.Root>

				{window.innerWidth > 1200 &&
					<>
						<div className={video ? "video active" : "video"} onClick={() => setVideo(false)}>
							<iframe src="https://www.youtube.com/embed/QNo-wzuPn_E?si=hI5DPlUt4-O9i-1F" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
						</div>

						<div
							className="h-[210vh] mt-[-50%] w-screen relative overflow-clip z-0"
							ref={ref}
						>
							<GoogleGeminiEffect
								className="top-[22%]"
								title={t('Home.Google.title')}
								description={t('Home.Google.description')}
								pathLengths={[
									pathLengthFirst,
									pathLengthSecond,
									pathLengthThird,
									pathLengthFourth,
									pathLengthFifth,
								]}
							/>
						</div>
					</>}

				<div className={`flex flex-col gap-[200px] items-center ${window.innerWidth > 1700 ? "mt-[-40vh]" : ""}`}>
					<div className="sellInfo">
						<div className="texts">
							<h1>{t('Home.Section0.title')}</h1>
							<p>{t('Home.Section0.description')}</p>
							<BasicBtn href="/contact " arrow text={t('Home.Section0.btn')} style={{ marginTop: "50px" }} />
						</div>
					</div>

					<div className="section1">
						<div className="textSection1">
							<h1>{t('Home.Section1.title')}</h1>
							<p>{t('Home.Section1.description')}</p>
							<BasicBtn fade={null} icon={IoLayersOutline} text='Home.Section1.btn' href='/platform' style={{ marginTop: "50px" }} />
						</div>
						<div className="numbers">
							<div className="infoNumbers">
								<h1>{t('Home.Section1.number1.title')}</h1>
								<p>{t('Home.Section1.number1.description')}</p>
							</div>
							<div className="infoNumbers">
								<h1>{t('Home.Section1.number2.title')}</h1>
								<p>{t('Home.Section1.number2.description')}</p>
							</div>
						</div>
						<SlArrowDown className="arrow1" />
						<SlArrowDown className="arrow2" />
					</div>

					<div className="secTotal">
						<div className="stage" />
						<div className="section2">
							<div className="sec_1">
								{offer.map((data, index) => [
									<div className="wapper" key={index}>
										<div className="imgBg">
											{index === 0 && <img src={"https://media.notcybersec.com/logo_rootkey/platform/apple_touch_120_120.png"} alt="" />}
											{index === 1 && <GiWindTurbine className="fill-rootkey-pink" />}
											{index === 2 && <BiCodeAlt />}
											{index === 3 && <MdBuildCircle />}
										</div>
										<div className="text">
											<h4>{t(data.title)}</h4>
											<p>{t(data.slogan)}</p>
										</div>
									</div>
								])}
							</div>
							<div className="sec_2">
								<div className="div1">
									<p>{t('Home.Section4.implemention.subtitle')}</p>
									<h1>{t('Home.Section4.implemention.title')}</h1>
									<p>{t('Home.Section4.implemention.number')}</p>
								</div>
								<div className="div2">
									<p>{t('Home.Section4.price.subtitle')}</p>
									<h1 style={{ color: "var(--rootkey-green)", display: "flex", alignItems: "flex-end", gap: "20px" }}><p style={{ fontWeight: "lighter", color: "#ccccc59b" }}>{t('Home.Section4.price.label')}</p>{t('Home.Section4.price.title')}</h1>
									<p>{t('Home.Section4.price.number')}</p>
								</div>
							</div>
						</div>
						<BasicBtn fade={null} href="/contact" text="Home.Section4.btn" arrow style={{ minWidth: "40%", borderRadius: "12px", borderColor: "var(--primary-color-trans-3)" }} />

					</div>
				</div>
			</main>
		</>
	)
} 
